import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import {
  CriteriaSuggestionDropdownValuesAdvanceQuery, CriteriaSuggestionDropdownValuesAdvanceQueryVariables,
  EntityTypeEnum
} from '../../../gql/typings';
import { Optional } from '../../util/StateArrayType';

type UseSuggestionDropdownValuesArgs = {
  search: string;
  service: string;
  criteria: {
    id: number;
    entityType: EntityTypeEnum;
  };
  criteriaPathId: number;
  countryCodes?: Optional<string[]>;

  selectedKeys: string[];
};

export type CriteriaSuggestionReturned = {
  records: CriteriaSuggestionRecordAdvance[];
  totalCount: Optional<number>;
  searchCount: Optional<number>;
  hasMoreResults: boolean;
  triggerLoading: () => void;
  fetchMore: () => void;
  loading: boolean;
};

export type CriteriaSuggestionRecordAdvance = NonNullable<
CriteriaSuggestionDropdownValuesAdvanceQuery['dropdownSuggestions']['nodes']['0']>;

const PER_LOAD = 100;

const buildCriteria = (args: UseSuggestionDropdownValuesArgs, offset: number) => ({
  limit: PER_LOAD,
  search: `%${args.search}%`,
  service: args.service,
  criteriaPathId: args.criteriaPathId,
  countryCodes: args.countryCodes,
  offset,
});

export const useSuggestionDropdownValuesAdvance = (args: UseSuggestionDropdownValuesArgs): CriteriaSuggestionReturned => {
  const totalCount = useRef<number>();

  const [load, {
    data,
    loading,
    refetch,
    fetchMore,
  }] = useLazyQuery<CriteriaSuggestionDropdownValuesAdvanceQuery,
  CriteriaSuggestionDropdownValuesAdvanceQueryVariables>(DATA_QUERY);

  useEffect(() => {
    refetch?.(buildCriteria(args, 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.search, refetch]);

  // Set the total count
  if (!totalCount.current && data?.dropdownSuggestions.totalCount) totalCount.current = data.dropdownSuggestions.totalCount;

  return {
    records: data?.dropdownSuggestions.nodes ?? [],
    totalCount: totalCount.current,
    searchCount: data?.dropdownSuggestions.totalCount,
    hasMoreResults: data ? (data.dropdownSuggestions.totalCount > data.dropdownSuggestions.nodes.length) : false,
    triggerLoading: () => load({ variables: buildCriteria(args, 0) }),
    loading,
    fetchMore: () => {
      if (fetchMore) fetchMore({
        variables: buildCriteria(args, data!.dropdownSuggestions.nodes.length),
      });
    },
  };
};


const DATA_QUERY = gql`
  query CriteriaSuggestionDropdownValuesAdvance(
    $countryCodes: [CountryCode!],
    $service: String!,
    $offset: Int!,
    $limit: Int!,
    $search: String
  ) {
    dropdownSuggestions(
      countryCodes: $countryCodes,
      service: $service,
      includeInactive: false,
      includeUnplaced: false,
      includeOptedOut: false,
      search: $search,
      fromEntityType: PERSON
      fetchSize: { offset: $offset, limit: $limit },
    ) {
      hash
      totalCount
      search
      service
      countryCodes
      nodes {
        key
        isGlobalOnly
        cacheKey
        isHeadingKey
        englishHeading
        heading
      }
    }
  }
`;
