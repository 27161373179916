import React, { lazy, CSSProperties, Suspense } from 'react';
import { isEmpty } from 'lodash';
import {
  AimOutlined, FieldNumberOutlined,
  FileDoneOutlined, FontColorsOutlined,
  IdcardOutlined,
  TagsOutlined, ToolOutlined, UnorderedListOutlined,
  UpOutlined,
  FileUnknownOutlined,
  UsergroupAddOutlined,
  LinkOutlined,
  MessageOutlined,
  LogoutOutlined,
  CaretDownOutlined, SwapOutlined,
  SearchOutlined,
  CarryOutOutlined, DeleteOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import activity from '../../../img/Activity_Icon.svg';
import createActivityWhite from '../../../img/icon-create-activity-white.svg';
import dashboard from '../../../img/icon-home-grey.svg';
import dcr from '../../../img/dcr.svg';
import dcrWhite from '../../../img/dcr - white.svg';
import deleteWhiteIcon from '../../../img/icon-delete-white.svg';
import importIcon from '../../../img/icon-import.svg';
import info from '../../../img/icon-info-grey.svg';
import key from '../../../img/icon-key.svg';
import list from '../../../img/icon-list-grey.svg';
import listWhite from '../../../img/icon-list-grey_white.svg';
import person from '../../../img/icon-contact-person.svg';
import open from '../../../img/icon-status-open.svg';
import statusRed from '../../../img/icon-status-red.svg';
import statusGreen from '../../../img/icon-status-green.svg';
import statusBlue from '../../../img/icon-status-blue.svg';
import statusGrey from '../../../img/icon-status-grey.svg';
import statusYellow from '../../../img/icon-status-yellow.svg';
import position from '../../../img/position.svg';
import project from '../../../img/icon-projects-grey.svg';
import projectWhite from '../../../img/icon-projects-white.svg';
import report from '../../../img/icon-reports-grey.svg';
import save from '../../../img/icon-save-grey.svg';
import saveWhite from '../../../img/icon-save-white.svg';
import site from '../../../img/icon-sites-grey.svg';
import chevronDown from '../../../img/icon-chevron-down.svg';
import GdprReady from '../../../img/icon-gdpr-active.svg';
import GdprInFormed from '../../../img/icon-gdpr-inactive.svg';
import { EntityTypeEnum } from '../../../gql/typings';


const CheckCircleFilled = lazy(() => import('@ant-design/icons/CheckCircleFilled'));
const CheckOutlined = lazy(() => import('@ant-design/icons/CheckOutlined'));
const ClearOutlined = lazy(() => import('@ant-design/icons/ClearOutlined'));
const SyncOutlined = lazy(() => import('@ant-design/icons/SyncOutlined'));
const CloseCircleFilled = lazy(() => import('@ant-design/icons/CloseCircleFilled'));
const CloseOutlined = lazy(() => import('@ant-design/icons/CloseOutlined'));
const DownOutlined = lazy(() => import('@ant-design/icons/DownOutlined'));
const EditOutlined = lazy(() => import('@ant-design/icons/EditOutlined'));
const CloseCircleOutlined = lazy(() => import('@ant-design/icons/CloseCircleOutlined'));
const FormOutlined = lazy(() => import('@ant-design/icons/FormOutlined'));
const CheckCircleOutlined = lazy(() => import('@ant-design/icons/CheckCircleOutlined'));
const LeftOutlined = lazy(() => import('@ant-design/icons/LeftOutlined'));
const LockOutlined = lazy(() => import('@ant-design/icons/LockOutlined'));
const PlusOutlined = lazy(() => import('@ant-design/icons/PlusOutlined'));
const MinusOutlined = lazy(() => import('@ant-design/icons/MinusOutlined'));
const ClockCircleOutlined = lazy(() => import('@ant-design/icons/ClockCircleOutlined'));
const RightCircleFilled = lazy(() => import('@ant-design/icons/RightCircleFilled'));
const CloudDownloadOutlined = lazy(() => import('@ant-design/icons/CloudDownloadOutlined'));
const PhoneOutlined = lazy(() => import('@ant-design/icons/PhoneOutlined'));
const EnvironmentOutlined = lazy(() => import('@ant-design/icons/EnvironmentOutlined'));
const RightOutlined = lazy(() => import('@ant-design/icons/RightOutlined'));
const CalendarOutlined = lazy(() => import('@ant-design/icons/CalendarOutlined'));
const SelectOutlined = lazy(() => import('@ant-design/icons/SelectOutlined'));
const SnippetsOutlined = lazy(() => import('@ant-design/icons/SnippetsOutlined'));
const SettingOutlined = lazy(() => import('@ant-design/icons/SettingOutlined'));
const HomeOutlined = lazy(() => import('@ant-design/icons/HomeOutlined'));
const UserOutlined = lazy(() => import('@ant-design/icons/UserOutlined'));
const StopFilled = lazy(() => import('@ant-design/icons/StopFilled'));
const ExpandAltOutlined = lazy(() => import('@ant-design/icons/ExpandAltOutlined'));
const ShareAltOutlined = lazy(() => import('@ant-design/icons/ShareAltOutlined'));
const GlobalOutlined = lazy(() => import('@ant-design/icons/GlobalOutlined'));
const AreaChartOutlined = lazy(() => import('@ant-design/icons/AreaChartOutlined'));
const FullscreenOutlined = lazy(() => import('@ant-design/icons/FullscreenOutlined'));
const FullscreenExitOutlined = lazy(() => import('@ant-design/icons/FullscreenExitOutlined'));
const UpdateOutlined = lazy(() => import('@ant-design/icons/RedoOutlined'));
const EyeInvisibleOutlined = lazy(() => import('@ant-design/icons/EyeInvisibleOutlined'));
const EyeOutlined = lazy(() => import('@ant-design/icons/EyeOutlined'));
const MergeCellsOutlined = lazy(() => import('@ant-design/icons/MergeCellsOutlined'));

export type ITE = keyof typeof EntityTypeEnum|'DCR_WHITE'|'LIST_WHITE'
|'PROJECT_WHITE'|'REPORT'|'IMPORT'|'KEY'|'OPEN'|'LOCATION'|'NOTE'|'CANCELLED'|'CANCEL_OUTLINED'
|'IN_PROGRESS'|'DONE'|'SAVE'|'DASHBOARD'|'CHECK'|'CHECK_OUTLINED'|'SEARCH'|'DOWN_ARROW'|'UP_ARROW'|'RIGHT_ARROW'|'LEFT_ARROW'
|'CLEAR'|'INFO'|'EDIT'|'LOCK'|'SETTINGS'|'LINK'|'DELETE'|'DELETE_WHITE'|'USER'|'EXPORT'|'SHARE'|'PLUS'|'MINUS'|'CLOSE'
|'PHONE'|'RADIO' |'INACTIVE'|'CREATE_ACTIVITY'|'LOGOUT'|'SELECT'|'SWITCH'|'SAVE_WHITE'|'STATUS_ICON_RED'|'STATUS_ICON_BLUE'
|'STATUS_ICON_GREEN'|'STATUS_ICON_GREY'|'STATUS_ICON_YELLOW'|'CALENDAR'|'TYPE'|'EXPAND'|'CLOCK'|'HOME'
|'LINE_CHART_FILLED'|'CHEVRON_DOWN'|'CONSENT'|'SEGMENTATION'|'EXTERNAL_ID'|'TAGS'|'RES_USERS'|'CUSTOM_FIELD'|'TEXT'|'NUMBER'
|'FULLSCREEN'|'EXIT_FULLSCREEN'|'GDPR_READY'|'EYE'|'EYE_INVISIBLE'|'ID_CARD'|'SYNC'|'UPDATE'|'HYPERLINK'
|'GDPR_INFORMED'|'MESSAGE'|'VALIDATE' | 'LOADING';

// TODO: These should be lazily loaded
const iconSelect: Record<ITE, React.FC<{ style: CSSProperties|undefined; className: string }>> = {
  // TODO: Provide icons for all these!
  ROLE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ACTIVITY_BRAND: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ACTIVITY_PRODUCT: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ACTIVITY_STATUS: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ACTIVITY_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  BRAND: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  BRICK: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CUSTOM_LINK_VALUE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  DCR_GROUP: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  EDUCATION: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  FILTER_CODE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  IMPORT_PROCESS_LINE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  IMPORT_PROJECT: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_ACTIVITY_AFFILIATION: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_FILTER_CODE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_PROJECT_AFFILIATION: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PREFERENCE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PRODUCT: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PROJECT_RESPONSE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PROJECT_ROLE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PROJECT_STATUS: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PROJECT_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SEGMENTATION_CODE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SITE_PERSON_WORK_SPECIALTY: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SITE_SPECIALTY: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SITE_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ADDRESS: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  ADDRESS_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CONTACT_VALUE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CONTACT_CATEGORY: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PURE_ADVANCE_CUSTOMER: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CUSTOM_VALUE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_PRODUCT_SEGMENTATION: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CHAIN: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CRITERIA: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SITE_SECTOR_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  EXTERNAL_ID_TYPE: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  CHERRY_PICK_OVERVIEW_CUSTOMER: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERS_SELECTION_ROW: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SITE_SELECTION_ROW: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  SELECTION_ROW_RESULT: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  PERSON_EDUCATION: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  WORK_SPECIALTY: ({ style, className }) => <FileUnknownOutlined style={style} className={className} />,
  // MARK: Here start all the real icons


  ACTIVITY: ({ style, className }) => <img alt='activity' src={activity} style={style} className={className} />,
  MESSAGE: ({ style, className }) => <MessageOutlined style={style} className={className} />,
  AFFILIATION: ({ style, className }) => <img alt='position' src={position} style={style} className={className} />,
  AFFILIATION_FROM_PERSON: ({ style, className }) => <img alt='position' src={position} style={style} className={className} />,
  AFFILIATION_FROM_SITE: ({ style, className }) => <img alt='position' src={position} style={style} className={className} />,
  CREATE_ACTIVITY: ({ style, className }) => <img
    alt='create-activity'
    src={createActivityWhite}
    style={style}
    className={className}
  />,
  DASHBOARD: ({ style, className }) => <img alt='dashboard' src={dashboard} style={style} className={className} />,
  DCR: ({ style, className }) => <img alt='dcr' src={dcr} style={style} className={className} />,
  DCR_WHITE: ({ style, className }) => <img alt='dcr' src={dcrWhite} style={style} className={className} />,
  DELETE_WHITE: ({ style, className }) => <img alt='delete' src={deleteWhiteIcon} style={style} className={className} />,
  IMPORT: ({ style, className }) => <img alt='list' src={importIcon} style={style} className={className} />,
  INFO: ({ style, className }) => <img alt='info' src={info} style={style} className={className} />,
  KEY: ({ style, className }) => <img alt='primary-key' src={key} style={style} className={className} />,
  LIST: ({ style, className }) => <img alt='list' src={list} style={style} className={className} />,
  LIST_WHITE: ({ style, className }) => <img alt='list' src={listWhite} style={style} className={className} />,
  DELETE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <DeleteOutlined style={style} className={className} />
    </Suspense>
  ),
  LOCATION: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <EnvironmentOutlined style={style} className={className} />
    </Suspense>
  ),
  LOGOUT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <LogoutOutlined style={style} className={className} />
    </Suspense>
  ),
  SELECT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CaretDownOutlined style={style} className={className} />
    </Suspense>
  ),
  SWITCH: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <SwapOutlined style={style} className={className} />
    </Suspense>
  ),
  PERSON: ({ style, className }) => <img alt='hcp' src={person} style={style} className={className} />,
  POSITION: ({ style, className }) => <img alt='position' src={position} style={style} className={className} />,
  PROJECT: ({ style, className }) => <img alt='project' src={project} style={style} className={className} />,
  PROJECT_WHITE: ({ style, className }) => <img alt='project' src={projectWhite} style={style} className={className} />,
  OPEN: ({ style, className }) => <img className={`icon-open ${className}`} alt='OPEN' src={open} style={style} />,
  STATUS_ICON_RED: ({ style, className }) => <img
    className={`icon-red ${className}`}
    alt='status red'
    src={statusRed}
    style={style}
  />,
  STATUS_ICON_BLUE: ({ style, className }) => <img
    className={`icon-blue ${className}`}
    alt='status blue'
    src={statusBlue}
    style={style}
  />,
  STATUS_ICON_GREEN: ({ style, className }) => <img
    className={`icon-blue ${className}`}
    alt='status green'
    src={statusGreen}
    style={style}
  />,
  STATUS_ICON_GREY: ({ style, className }) => <img
    className={`icon-grey ${className}`}
    alt='status grey'
    src={statusGrey}
    style={style}
  />,
  STATUS_ICON_YELLOW: ({ style, className }) => <img
    className={`icon-yellow ${className}`}
    alt='status grey'
    src={statusYellow}
    style={style}
  />,
  REPORT: ({ style, className }) => <img alt='report' src={report} style={style} className={className} />,
  SAVE: ({ style, className }) => <img alt='save' src={save} style={style} className={className} />,
  SAVE_WHITE: ({ style, className }) => <img alt='save-white' src={saveWhite} style={style} className={className} />,
  SEARCH: ({ style, className }) => <Suspense fallback={<span />}>
    <SearchOutlined style={style} className={className} />
  </Suspense>,
  LANGUAGE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <GlobalOutlined className={`icon-calendar ${className}`} style={style} />
    </Suspense>
  ),
  SHARE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <ShareAltOutlined className={`icon-calendar ${className}`} style={style} />
    </Suspense>
  ),
  SITE: ({ style, className }) => <img alt='hco' src={site} style={style} className={className} />,
  CANCELLED: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <StopFilled className={`icon-cancel ${className}`} style={style} />
    </Suspense>
  ),
  ENTITY_MERGE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <MergeCellsOutlined style={style} className={className} />
    </Suspense>
  ),
  CLOCK: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <ClockCircleOutlined className={`icon-clock ${className}`} style={style} />
    </Suspense>
  ),
  CANCEL_OUTLINED: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CloseCircleOutlined className={`icon-cancel-outlined ${className}`} style={style} />
    </Suspense>
  ),
  CALENDAR: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CalendarOutlined className={`icon-calendar ${className}`} style={style} />
    </Suspense>
  ),
  HOME: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <HomeOutlined className={`icon-home ${className}`} style={style} />
    </Suspense>
  ),
  CONSENT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <FileDoneOutlined className={`icon-calendar ${className}`} style={style} />
    </Suspense>
  ),
  CHECK: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CheckOutlined style={style} className={className} />
    </Suspense>
  ),
  CHECK_OUTLINED: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CheckCircleOutlined className={`icon-check-outlined ${className}`} style={style} />
    </Suspense>
  ),
  CLEAR: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <ClearOutlined style={style} className={className} />
    </Suspense>
  ),
  SYNC: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <SyncOutlined style={style} className={className} />
    </Suspense>
  ),
  PHONE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <PhoneOutlined style={style} className={className} />
    </Suspense>
  ),
  TYPE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <SnippetsOutlined style={style} className={className} />
    </Suspense>
  ),
  CLOSE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CloseOutlined style={style} className={className} />
    </Suspense>
  ),
  DONE: ({ style, className }) => (<Suspense fallback={<span />}>
    <CheckCircleFilled className={`icon-done ${className}`} style={style} />
  </Suspense>),
  DOWN_ARROW: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <DownOutlined style={style} className={className} />
    </Suspense>
  ),
  UP_ARROW: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <UpOutlined style={style} className={className} />
    </Suspense>
  ),
  EDIT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <EditOutlined style={style} className={className} />
    </Suspense>
  ),
  INACTIVE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CloseCircleFilled className={`icon-in-active ${className}`} style={style} />
    </Suspense>
  ),
  UPDATE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <UpdateOutlined className={`icon-in-active ${className}`} style={style} />
    </Suspense>
  ),
  HYPERLINK: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <LinkOutlined className={`icon-in-active ${className}`} style={style} />
    </Suspense>
  ),
  EXPORT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CloudDownloadOutlined className={`icon-export ${className}`} style={style} />
    </Suspense>
  ),
  TEXT: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <FontColorsOutlined className={`icon-text ${className}`} style={style} />
    </Suspense>
  ),
  NUMBER: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <FieldNumberOutlined className={`icon-number ${className}`} style={style} />
    </Suspense>
  ),
  RADIO: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <UnorderedListOutlined className={`icon-radio ${className}`} style={style} />
    </Suspense>
  ),
  IN_PROGRESS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <RightCircleFilled className={`icon-in-progress ${className}`} style={style} />
    </Suspense>
  ),
  LOADING: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <LoadingOutlined className={`loading ${className}`} style={style} />
    </Suspense>
  ),
  SEGMENTATION: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <AimOutlined className={`icon-aim ${className}`} style={style} />
    </Suspense>
  ),
  EXTERNAL_ID: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <IdcardOutlined className={`icon-id-external ${className}`} style={style} />
    </Suspense>
  ),
  TAGS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <TagsOutlined className={`icon-tags ${className}`} style={style} />
    </Suspense>
  ),
  RES_USERS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <UsergroupAddOutlined className={`icon-res-users ${className}`} style={style} />
    </Suspense>
  ),
  VALIDATE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <CarryOutOutlined className={`icon-res-users ${className}`} style={style} />
    </Suspense>
  ),
  CUSTOM_FIELD: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <ToolOutlined className={`icon-custom-field ${className}`} style={style} />
    </Suspense>
  ),
  LEFT_ARROW: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <LeftOutlined style={style} className={className} />
    </Suspense>
  ),
  LINK: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <SelectOutlined style={style} className={className} />
    </Suspense>
  ),
  LOCK: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <LockOutlined style={style} className={className} />
    </Suspense>
  ),
  NOTE: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <FormOutlined style={style} className={className} />
    </Suspense>
  ),
  PLUS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <PlusOutlined style={style} className={className} />
    </Suspense>
  ),
  MINUS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <MinusOutlined style={style} className={className} />
    </Suspense>
  ),
  RIGHT_ARROW: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <RightOutlined style={style} className={className} />
    </Suspense>
  ),
  SETTINGS: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <SettingOutlined className={`icon-settings ${className}`} style={style} />
    </Suspense>
  ),
  USER: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <UserOutlined style={style} className={className} />
    </Suspense>
  ),
  EXPAND: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <ExpandAltOutlined style={style} className={className} />
    </Suspense>
  ),
  LINE_CHART_FILLED: ({ style, className }) => (
    <Suspense fallback={<span />}>
      <AreaChartOutlined style={style} className={className} />
    </Suspense>
  ),
  CHEVRON_DOWN: ({ style = {}, className }) => <img
    alt="chevron down"
    style={{ width: 18, height: 10, ...style }}
    className={className}
    src={chevronDown}
  />,
  GDPR_READY: ({ style = {}, className }) => <img
    alt="chevron down"
    style={{ height: 24, ...style }}
    className={className}
    src={GdprReady}
  />,
  GDPR_INFORMED: ({ style = {}, className }) => <img
    alt="chevron down"
    style={{ height: 24, ...style }}
    className={className}
    src={GdprInFormed}
  />,
  FULLSCREEN: ({ style = {}, className }) => (
    <Suspense fallback={<span />}>
      <FullscreenOutlined style={style} className={className} />
    </Suspense>
  ),
  EXIT_FULLSCREEN: ({ style = {}, className }) => (
    <Suspense fallback={<span />}>
      <FullscreenExitOutlined style={style} className={className} />
    </Suspense>
  ),
  EYE: ({ style = {}, className }) => (
    <Suspense fallback={<span />}>
      <EyeOutlined style={style} className={className} />
    </Suspense>
  ),
  EYE_INVISIBLE: ({ style = {}, className }) => (
    <Suspense fallback={<span />}>
      <EyeInvisibleOutlined style={style} className={className} />
    </Suspense>
  ),
  ID_CARD: ({ style = {}, className }) => (
    <Suspense fallback={<span />}>
      <IdcardOutlined style={style} className={className} />
    </Suspense>
  ),
};

type IconProps = {
  iconType: ITE;
  text?: string|React.ReactNode;
  className?: string;
  textClassName?: string;
  toolTip?: string|null;
  toolTipZIndex?: number;
  iconBeforeText?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
  iconOnly?: boolean;
};

const Icon: React.FC<IconProps> = ({
  iconType,
  text,
  className,
  textClassName,
  toolTip,
  toolTipZIndex,
  style,
  onClick,
  iconBeforeText = true,
  iconOnly = false
}) => {
  if (isEmpty(iconType)) return <></>;
  const IconToUse = iconSelect[iconType];
  if (!IconToUse) return <span>The Icon {iconType} is not supported!!</span>;
  if (iconOnly) return (
    <span
      className={`${className ?? ''} ${onClick ? 'clickable' : ''}`.trim()}
      onClick={onClick}
      onBlur={(event) => event.preventDefault()}
    >
      <IconToUse className="apb-icon" style={{ display: 'flex', ...style }} />
    </span>
  );
  return (<Tooltip
    destroyTooltipOnHide={!!toolTip}
    title={toolTip}
    mouseEnterDelay={2}
    zIndex={toolTipZIndex}
  >
    <span
      className={`icon-container ${className ?? ''} ${onClick ? 'clickable' : ''}`.trim()}
      onClick={onClick}
      onBlur={(event) => event.preventDefault()}
    >
      {iconBeforeText && <IconToUse className="apb-icon" style={style} />}
      {text && <span className={textClassName || ''}>{text}</span>}
      {!iconBeforeText && <IconToUse className="apb-icon" style={style} />}
    </span></Tooltip>
  );
};

export default Icon;
