import React from 'react';
import { Alert, Badge, Card, FormInstance, Popover, Space } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { flatten } from 'lodash';
import { Locale } from '../../../../localization/LocalizationKeys';
import GlowButton from '../../../components/Input/GlowButton';
import Icon from '../../../components/Icon/Icon';
import { useLocalization } from '../../../util/useLocalization';
import { AddWorkplaceInput } from '../../../../gql/typings';

type CreatePersonAddWorkspaceInformationCardProps = {
  form: FormInstance<AddWorkplaceInput>;
  cardInfo: AddWorkspaceInformationCard|undefined;
  clear: () => void;
  setVisible: (visible: boolean) => void;
};

export type AddWorkspaceInformationCard = {
  name: string | null;
  positionLabel: string | null;
  countryCode: string | null;
  missing: boolean;
};

const CreatePersonAddWorkspaceInformationCard: React.FC<CreatePersonAddWorkspaceInformationCardProps> = ({
  form,
  cardInfo,
  clear,
  setVisible,
}) => {
  const localization = useLocalization();

  if (!cardInfo) return (
    <GlowButton
      label={localization.formatMessage(Locale.Command.Add_workplace)}
      onClick={() => setVisible(true)}
      labelPlacement="left"
      size="x-small"
      className="add-workspace"
    />
  );

  const errors = form.getFieldsError();

  return (
    <Card
      bordered
      actions={[
        <DeleteOutlined key="delete" onClick={clear} />,
        <EditOutlined key="modify" onClick={() => setVisible(true)} />,
      ]}
    >
      <Card.Meta
        title={cardInfo.name || <i>Missing required workplace name</i>}
        description={cardInfo.positionLabel || <i>Missing required workplace position</i>}
        avatar={
          errors.length > 0 ? (
            <Popover
              content={
                <Space direction="vertical">
                  {errors.map((field, index) => field.errors.map((errorMsg, i) => (
                    <Alert key={`${index}${i}`} type="error" message={errorMsg} />
                  )))}
                </Space>
              }
            >
              <Badge count={flatten(errors.map(e => e.errors)).length}>
                <Icon iconType="AFFILIATION" />
              </Badge>
            </Popover>
          ) : <Icon iconType="AFFILIATION" />
        }
      />
    </Card>
  );
};

export default CreatePersonAddWorkspaceInformationCard;
