
import React, { useContext, useMemo, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Empty, Input, message, Skeleton, Space, Tree, TreeDataNode, TreeProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useThrottle } from 'ahooks';
import {
  DeleteSearchMutation, DeleteSearchMutationVariables,
  MyAdvanceSavedSearchesDocument,
  MyAdvanceSavedSearchesQuery, SaveAsDefaultMutation, SaveAsDefaultMutationVariables
} from '../../../../../gql/typings';
import { useLocalization } from '../../../../util/useLocalization';
import EntitiesSearchContext from '../../EntitiesSearchContext';
import { StateArray } from '../../../../util/StateArrayType';
import { Locale } from '../../../../../localization/LocalizationKeys';
import Icon from '../../../Icon/Icon';
import { useURLSearch } from '../../../../util/Util';


type AdvanceSavedSearchesType = {
  drawerState: StateArray<boolean>;
};
const AdvanceSavedSearches: React.FC<AdvanceSavedSearchesType> = ({
  drawerState
}) => {
  const localization = useLocalization();
  const {
    setSelectedQueryId,
  } = useContext(EntitiesSearchContext);
  // const { onSetActive } = useContext(LightCollapseContext);
  const { DirectoryTree } = Tree;
  const { setSearch: setUrlSearch } = useURLSearch();
  const [search, setSearch] = useState('');
  const searchValue = useThrottle(search, { wait: 500 });
  const { data, loading } = useQuery<MyAdvanceSavedSearchesQuery>(DATA_QUERY);
  const [saveAsDefault] = useMutation<SaveAsDefaultMutation, SaveAsDefaultMutationVariables>(SAVE_AS_DEFAULT_MUTATION);
  const [deleteQuery] = useMutation<DeleteSearchMutation, DeleteSearchMutationVariables>(DELETE_QUERY_MUTATION);

  const results = useMemo(() => {
    const org = data?.allSearches.nodes ?? [];
    return [...org].reverse();
  }, [data]);

  const onSearchSelect: TreeProps['onSelect'] = (keys, _) => {
    if (keys && keys.length > 0) {
      setUrlSearch({});
      setSelectedQueryId && setSelectedQueryId(keys[0] as number);
      drawerState[1](false);
    }
  };

  // const onEditAdvanceSearchQuery = (searchId: number, e: React.MouseEvent<HTMLElement>) => {
  //   e.stopPropagation();
  //   onSetActive(2);
  //   editState[1](searchId);
  // };

  const onSaveAsDefaultAdvanceSearch = (searchId: number, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    saveAsDefault({
      variables: {
        searchID: searchId
      },
      refetchQueries: [{
        query: MyAdvanceSavedSearchesDocument,
        fetchPolicy: 'network-only'
      }]
    },).then(() => message.success(localization.formatMessage(Locale.Text.Search_saved_as_default)));
  };

  const onDeleteSavedAdvanceQuery = (searchId: number, heading: string, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    deleteQuery({
      variables: {
        searchID: searchId
      },
      refetchQueries: [{
        query: MyAdvanceSavedSearchesDocument,
        fetchPolicy: 'network-only'
      }]
    }).then(() => message
      .success(
        localization.formatMessage(Locale.Text.Successfully_delete_saved_search,
          { name: <b>{heading}</b> })
      ));
  };
  const treeDataNode: TreeDataNode[] = [{
    key: '0-1',
    title: <span onClick={e => e.stopPropagation()}>aPureBase</span>,
    children: results.map(savedCriteria => (
      {
        key: savedCriteria.id,
        title: <div className="saved-advance-searches">
          <Highlighter searchWords={[searchValue]} textToHighlight={savedCriteria.heading!} />
          {
            !savedCriteria.isDefault ? (
              <div className="search-action-buttons">
                {/* <span onClick={(e) => onEditAdvanceSearchQuery(savedCriteria.id, e)}> */}
                {/*  <Icon */}
                {/*    className='action-button' */}
                {/*    iconType="EDIT" */}
                {/*    style={{ width: '13px', height: '13px' }} */}
                {/*  /> */}
                {/* </span> */}
                <span onClick={(e) => onSaveAsDefaultAdvanceSearch(savedCriteria.id, e)}>
                  <Icon
                    className='action-button'
                    iconType="SAVE"
                    style={{ width: '13px', height: '13px' }}

                  />
                </span>
                <span onClick={(e) => onDeleteSavedAdvanceQuery(savedCriteria.id, savedCriteria.heading!, e)}>
                  <Icon
                    className='action-button'
                    iconType="DELETE"
                  />
                </span>


              </div>
            ) : <span className='default-query'>Default</span>
          }
        </div>,
        isLeaf: true,
        icon: <SearchOutlined />
      }
    ))
  }];


  return (
    <Space size="middle" direction="vertical" className="simple-saved-searches-container">
      <Input.Search
        className="search"
        allowClear
        loading={loading}
        onChange={e => setSearch(e.target.value)}
      />
      <DirectoryTree
        multiple
        treeData={treeDataNode}
        onSelect={onSearchSelect}
        className='advance-saved-search-tree'

      />

      {/*  {results.map(savedCriteria => (
        <Space
          key={savedCriteria.id}
          onClick={() => {
            setDefaultQueryId && setDefaultQueryId(savedCriteria.id);
            drawerState[1](false);
          }}
          className={savedCriteria.id === (selectedSaved && selectedSaved.id) ? 'row selected' : 'row'}
        >
          {savedCriteria.heading && <Space className="left">
            <SearchOutlined />
            <Highlighter searchWords={[searchValue]} textToHighlight={savedCriteria.heading} />
          </Space>}
          <Space className="right">
            {localization.pluralMessage(Locale.Text.Saved_results, savedCriteria.totalCount) }
          </Space>
        </Space>
      ))} */}
      {results.length === 0 && (loading
        ? <Skeleton loading active title={false} paragraph={{ rows: 4, width: '100%' }} />
        : <Empty />
      )}
    </Space>
  );
};
export default AdvanceSavedSearches;

const DATA_QUERY = gql`
  query MyAdvanceSavedSearches {
    allSearches {
      totalCount
      nodes {
        heading
        id
        groupingCode
        isDefault
      }
    }
  }
`;

const SAVE_AS_DEFAULT_MUTATION = gql`
  mutation SaveAsDefault($searchID: ID!) {
    defaultSearch(searchId: $searchID) {
      searchId
    }
  }
`;

const DELETE_QUERY_MUTATION = gql`
mutation DeleteSearch($searchID: ID!) {
  deleteSearch(searchId: $searchID)
}`;

