import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Form, Select, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { get, isEqual } from 'lodash';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import * as UUID from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NamePath, StoreValue } from 'rc-field-form/es/interface';
import { useColors } from '../../../util/useColor';
import { SearchConditionEnum } from '../../../../gql/typings';
import RangePicker from '../../RangePicker/RangePicker';
import { SearchFormItem, SearchInputKeyName } from './SearchFormItem';
import EntityCascader from '../../EntityCascader/EntityCascader';
import {
  defaultInputOptions,
  inputOptionsGenerator, operatorOptions,
  searchConditionOptions
} from './options';
import SearchConditionGroupInput from './SearchConditionGroupInput';
import SearchConditionElementInput from './SearchConditionElementInput';
import { AdvanceSearchContext } from './AdvancedSearchBuilder';
import { useLocalization } from '../../../util/useLocalization';
import { AdvanceSearchInputCriteria } from '../entitiesSearchAdvance';

type ConditionRendererProps = {
  inputPaths: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  onChange: () => void;
  typeValue: string | undefined;
  code?: string;
};
type SearchConditionElement = {
  inputPaths: SearchInputKeyName[];
  valuePath: SearchInputKeyName[];
  isEntitySelect: boolean;
  removeElement: () => void;
};

type SearchConditionEnumWithoutNest = Exclude<SearchConditionEnum, SearchConditionEnum.NEST>;


export enum InputElements {
  Text = 'Text',
  Date = 'Date',
  MultiSelect = 'Select',
  Select = 'Select',
  Switch = 'Switch',
  UserInput = 'UserInput',
  CustomInput = 'CustomInput'
}

const ConditionRenderer: React.FC<ConditionRendererProps> = ({
  inputPaths,
  valuePath,
  onChange,
  typeValue,
  code
}) => {
  const { entityType } = useContext(AdvanceSearchContext);
  if (!typeValue) return <></>;
  const EntityCascaderFormItem: React.FC<
  { showAttributes?: boolean;
    onChange: () => void; }
  > = useCallback(({ showAttributes = true, onChange }) => (
    <div className="search-condition-element-property">
      <EntityCascader
        inputPath={[...inputPaths, 'paths']}
        showAttributes={showAttributes}
        code={code}
        onChangeFn={onChange}
        rules={[
          {
            required: true,
            message: 'Please input value'
          }
        ]}
      />
    </div>
  ), // eslint-disable-next-line react-hooks/exhaustive-deps
  []);


  const test: Record<SearchConditionEnumWithoutNest, React.FC<{ valuePath?: SearchInputKeyName[] }>> = useMemo(() => (
    {
      [SearchConditionEnum.COUNT]: () => <>
        <div style={{ display: 'inline-flex', gridGap: '10px' }}>
          <RangePicker inputPath={[...inputPaths]} name={['operation', 'value']} valuePath={valuePath} disabled={false} />
          <EntityCascaderFormItem showAttributes={false} onChange={onChange} />
        </div>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev !== next}
          rules={[{ required: true, message: 'Please input a value' }]}
        >
          {
            ({ getFieldValue }) => {
              const pathArray = getFieldValue([...valuePath, 'paths']);
              if (!pathArray) return <></>;
              const pathObject = JSON.parse(pathArray[0]);
              let toUseEntityType = entityType;
              if (pathObject.entityType) {
                toUseEntityType = pathObject.entityType;
              }
              return (
                <div className="search-condition-element-type">
                  <AdvanceSearchContext.Provider
                    value={{
                      entityType: toUseEntityType
                    }}
                  >
                    <SearchConditionGroupInput
                      inputPath={[...inputPaths, 'nest']}
                      valuePath={[...valuePath, 'nest']}
                      nest
                    />
                  </AdvanceSearchContext.Provider>
                </div>
              );
            }
          }
        </Form.Item>
      </>,
      /* [SearchConditionEnum.NEST]: () => <div className="search-condition-element-type">
        <SearchConditionGroupInput
          inputPath={[...inputPaths, 'nest']}
          valuePath={[...valuePath, 'nest']}
          entityType={EntityTypeEnum.PERSON}
          nest
        />
      </div>, */
      [SearchConditionEnum.INCLUDE]: () => <EntityCascaderFormItem onChange={onChange} />,
      [SearchConditionEnum.EXCLUDE]: () => <EntityCascaderFormItem onChange={onChange} />,
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const opt = typeValue as SearchConditionEnumWithoutNest;
  const Component = useMemo(() => test[opt], [test, opt]);
  return <Component valuePath={valuePath} />;
};

const SearchConditionElement: React.FC<SearchConditionElement> = React.memo(
  ({
    inputPaths,
    valuePath,
    removeElement }) => {
    const [uuidCode] = useState(UUID.v4());
    const { formatMessage } = useLocalization();
    const { colorError } = useColors();
    const formInst = useFormInstance();
    const [selectedCondition, setSelectedCondition] = useState<SearchConditionEnum>();
    const [currentInput, setCurrentInput] = useState<InputElements | null>(null);
    const getAttributeData = (getter: (name: NamePath) => StoreValue, path: SearchInputKeyName[]) => {
      const pathsObj = getter(path);
      if (!pathsObj) return null;
      const toParseData = pathsObj[pathsObj.length - 1];
      if (typeof toParseData != 'string') return toParseData;
      return JSON.parse(toParseData);
    };

    return (
      <div className='search-condition-element-container'>
        <div className="search-condition-header">
          <div
            className={`search-condition-header-inputs
             ${(selectedCondition === SearchConditionEnum.NEST 
              || selectedCondition === SearchConditionEnum.COUNT) ? 'header-inputs-display-block' : ''}`}
          >
            <Form.Item name={[...inputPaths, 'code']} initialValue={uuidCode} hidden>
              <Input />
            </Form.Item>
            <SearchFormItem
              name={[...inputPaths, 'type']}
              rules={[{
                required: true,
                message: 'Please input a value'
              }]}
            >
              <Select
                placeholder='Select Condition'
                style={{ minWidth: '100px', width: '120px' }}
                options={searchConditionOptions(formatMessage)}
                size='small'
                onChange={(value) => {
                  setSelectedCondition(value);
                  formInst.setFieldValue([...valuePath, 'paths'], undefined);
                //  formInst.setFieldValue([...valuePath, 'operation'], undefined);
                //  formInst.setFieldValue([...valuePath, 'value'], undefined);
                }}
              />
            </SearchFormItem>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => {
                const prevType = get(prev, [...valuePath, 'type']);
                const nextType = get(next, [...valuePath, 'type']);
                //  console.log('prev', prevType, 'next', nextType);
                return prevType != nextType;
              }}
            >
              {({ getFieldValue, setFields }) => {
                const typeFieldValue = getFieldValue([...valuePath, 'type']);
                return (
                  <ConditionRenderer
                    inputPaths={inputPaths}
                    valuePath={valuePath}
                    typeValue={typeFieldValue}
                    code={uuidCode}
                    onChange={() => {
                      setFields([
                        {
                          name: [...valuePath, 'inputConfiguration', 'inputType'],
                          value: null
                        },
                        {
                          name: [...valuePath, 'value'],
                          value: null
                        }
                      ]);
                      setCurrentInput(null);
                    }}
                  />
                );
              }}
            </Form.Item>
          </div>
          <DeleteOutlined style={{ color: colorError, position: 'absolute', top: '12px', right: 0 }} onClick={removeElement} />
        </div>
        {/* // starts from her */}


        {
          selectedCondition !== SearchConditionEnum.COUNT && (
            <div
              className={`search-condition-path-values ${currentInput === InputElements.CustomInput
                ? 'custom-input-height' : ''}`}
            >
              {/*  <Form.Item noStyle shouldUpdate={() => true}>
                {
                  ({ getFieldValue }) => {
                    if (!getFieldValue([...valuePath, 'paths'])) return <></>;
                    return (
                      <Form.Item>
                        <Select
                          placeholder='Select operator'
                          defaultValue='like'
                          style={{ width: '100%', minWidth: '100px' }}
                          options={operatorConditions}
                          size='small'
                        />
                      </Form.Item>

                    );
                  }
                }
              </Form.Item> */}
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => !isEqual(get(prev, [...valuePath, 'paths']), get(next, [...valuePath, 'paths']))}
              >
                {
                  ({ getFieldValue }) => {
                    const attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                    if (!attributeValue) return <></>;
                    let inputOptions;
                    if (!attributeValue.inputType) {
                      inputOptions = defaultInputOptions(formatMessage);
                    } else {
                      inputOptions = inputOptionsGenerator(attributeValue.inputType, formatMessage);
                    }
                    return (
                      <Form.Item
                        rules={[{ required: true, message: 'Please input a value' }]}
                      >
                        <Select
                          placeholder='Select input condition'
                          style={{ width: '100%', minWidth: '100px' }}
                          options={inputOptions}
                          size='small'
                          onChange={(val) => setCurrentInput(val)}
                        />
                      </Form.Item>
                    );
                  }
                }
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => !isEqual(get(prev, [...valuePath, 'paths']), get(next, [...valuePath, 'paths']))}
              >
                {
                  ({ getFieldValue }) => {
                    const attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                    if (!attributeValue) return <></>;
                    let inputOptions;
                    const { dataType, inputType } = attributeValue;
                    if (dataType === 'String'
                      && inputType === 'Text') {
                      inputOptions = operatorOptions(true);
                    } else if (attributeValue.dataType === 'Int' && inputType === 'Text') {
                      inputOptions = operatorOptions(false);
                    } else if (attributeValue.inputType === 'Select' || attributeValue.inputType == 'MultiSelect') {
                      formInst.setFieldValue([...valuePath, 'operation'], 'IN');
                      return <></>;
                    } else {
                      return <></>;
                    }
                    return (
                      <Form.Item
                        name={[...inputPaths, 'operation']}
                        rules={[{ required: true, message: 'Please input a value' }]}
                      >
                        <Select
                          placeholder='Select'
                          style={{ width: '100%', minWidth: '100px' }}
                          options={inputOptions}
                          size='small'
                        />
                      </Form.Item>
                    );
                  }
                }
              </Form.Item>
              {
                currentInput && <Form.Item noStyle shouldUpdate={() => true}>
                  {
                    ({ getFieldValue }) => {
                      const attributeValue = getAttributeData(getFieldValue, [...valuePath, 'paths']);
                      if (!attributeValue) return <></>;
                      const configs: AdvanceSearchInputCriteria = {
                        service: attributeValue.serviceName,
                        heading: attributeValue.heading,
                        isHeadingKey: attributeValue.isHeadingKey,
                        inputType: attributeValue.inputType,
                        inputHeadingMeta: attributeValue.inputHeadingMeta,
                        placeholder: '',
                        code: attributeValue.code,
                        criteriaId: attributeValue.code,
                        value: ''
                      };
                      return (
                        <SearchConditionElementInput
                          inputPaths={[...inputPaths]}
                          valuePaths={valuePath}
                          inputType={currentInput}
                          inputConfigs={configs}
                        />
                      );
                    }
                  }
                </Form.Item>
              }
            </div>
          )
        }
      </div>
    );
  }
);
export default SearchConditionElement;
